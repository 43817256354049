/* You can add global styles to this file, and also import other style files */
// See https://getbootstrap.com/docs/5.1/customize/overview/
// Note: some Bootstrap elements directly use SCSS variables such as $primary,
// while others use the $theme-colors array. So this order matters:
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'variables.scss';
@import 'bootstrap/scss/bootstrap';
// Icons from the bootstrap-icons package, see https://icons.getbootstrap.com/
@import 'bootstrap-icons/font/bootstrap-icons.css';
// flags for international phone input
@import 'intl-tel-input/build/css/intlTelInput.css';
// quill styles
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';
@import 'print.css';

// this should match environment.navbarHeight
$navbar-height: 96px;
// this should match environment.genericSetupFixedHeight
$generic-setup-fixed-height: 300px;

html, body {
  height: 100%;
  font-family: "Segoe UI", Arial, sans-serif;
}

body.modal-open .hidden-with-modal-present {
  display: none;
}

div, span {
  .form-control.disabled {
    opacity: 1;
  }
}

.base-layout-container {
  /* need to define height so middle-column has something to work against */
  /* note ResizeObserver magic in ErrorComponent */
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;

  .left-column {
    width: 200px;
    flex-basis: 200px;

    .left-panel-card {
      margin-bottom: 20px;
    }
  }

  /* Scrollspy container */
  .middle-column {
    width: 700px;
    flex-basis: 700px;
    overflow-y: scroll;
    max-height: 100%;

    .middle-panel-card {
      margin-bottom: 20px;
    }

    #data-content {
      height: calc(100vh - $navbar-height - 20px);
    }

    .col-input {
      flex-grow: 1;
    }

    .row:not(:has(.col-actions)) .col-input {
      margin-right: 75px;
    }

    .col-actions {
      width: 75px;
    }
  }

  .middle-and-right-column {
    width: 1070px;
    flex-basis: 1070px;
    overflow-y: scroll;
    max-height: 100%;

    .middle-panel-card {
      margin-bottom: 20px;
    }
  }

  .right-column {
    width: 370px;
    flex-basis: 370px;
  }

  .full-width-column {
    width: 1270px;
    flex-basis: 1270px;

    .scroll-container {
      height: calc(100vh - $navbar-height - $generic-setup-fixed-height);
      overflow-y: scroll;
    }
  }

  h3 {
    text-align: left;
    font-size: 1.5em;
    font-weight: 400;
  }

  h4 {
    text-align: center;
    font-size: 1.2em;
    font-weight: 100;
  }
}

.publishing-warning {
  color: #eeb20f;
}

.publishing-ok {
  color: $faces-main;
}

.toast.bg-success {
  background-color: #3bc1ca !important;
}

.ordered-form-section {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 4px;
}

@mixin ordered-row-list {
  @for $i from 1 through 200 {
      .uf-ordered-row-#{$i} { order: $i; }
  }
}

@include ordered-row-list;

// custom modal size for Sabre Red
@media (max-width: 992px) {
  .modal-dialog.modal-embed {
    max-width: 80vw;
  }
}

@media (min-width: 992px) {
  .modal-dialog.modal-embed {
    --bs-modal-width: 800px;
    max-width: var(--bs-modal-width);
  }
}

.generic-value-group {
  order: 990;
}

.generic-field-visiblity-toggle {
  order: 999;
}

div.spacer {
  margin-bottom: 10px;
}

.col-header {
  font-weight: bold;
}

.flex-row-justify {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.small-text {
  font-size: 80%;
  color: gray;
}

.pseudo-link {
  cursor: pointer;
  text-decoration: underline;
}

.pseudo-button {
  cursor: pointer;
}

.float-bottom-mobile button {
    width: 100%;
    margin: 3px;
}

.hidden-link {
  color: currentColor;
  text-decoration: none;
}

.hide-on-readonly.readonly,
.uf-formstatus-disabled .hide-on-readonly {
  display: none;
}

.one-to-many {
  @extend .mb-3;
}

// intl-tel-input styles
.iti.iti--allow-dropdown {
  width: 100%;
}

.iti__country-list .iti__country[data-country-code='globe'] {
  .iti__dial-code {
    display: none;
  }

  .iti__country-name {
    display: none;
  }
}

.col-input:not(:has(.form-check)) {
  @extend .d-flex;
  @extend .align-items-center;

  > *:has(.input-group, .form-control, .form-select) {
    @extend .d-flex;
    @extend .flex-grow-1;
  }

  .input-group {
    width: unset;
    @extend .flex-grow-1;
  }
}

// CC Entry via Tokenizer
.TokenizedCardNumber, .TokenizedCvv {
  input, input:focus {
    outline: none;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
  }

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: $desktop-width) {
  .hide-on-mobile {
    // note that Bootstrap defines 'display: inline-block;' for various elements
    display: none !important;
  }

  .float-bottom-mobile .action-buttons {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-around;
  }

  .workarea-width {
    width: 100%;
    margin: auto;
  }

  .base-layout-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    // this padding allows scrolling content past the floating buttons at the bottom
    padding-bottom: 120px;

    .left-column {
      width: 100%;
      flex-basis: auto;

      .left-panel-card {
        margin-bottom: 20px;
      }

      .float-bottom-mobile {
        margin-bottom: 20px;
        .left-panel-card {
          margin-bottom: 0px;
        }
      }
    }

    /* Scrollspy container */
    .middle-column {
      width: 100%;
      height: inherit;
      overflow-y: inherit;
    }

    .right-column {
      width: 100%;
      flex-basis: auto;
    }
  }

  .float-bottom-mobile {
    position: fixed !important;
    bottom: 0;
    z-index: 100;
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .col-input.input-group {
    width: 100%;
  }
}
