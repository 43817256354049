@media print {
  .right-column, .left-column {
    display: none !important;
  }

  .btn {
    display: none !important;
  }

  .uf-traveller-uuid {
    display: none !important;
  }

  .iti__selected-flag .iti__arrow, .iti__selected-flag .iti__flag {
    display: none !important;
  }

  .input-group-text {
    display: none !important;
  }

  .row .col-label {
    width: 25% !important;
  }

  .row .col-input {
    flex: 1 !important;
  }

  input:not([type="checkbox"]):not([type="radio"]), select, textarea {
    border: none !important;
  }

  select {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
  }

  nav {
    display: none !important;
  }

  .error-container {
    display: none !important;
  }

}
